





























import { Component, Vue } from 'vue-property-decorator'
import RowSelector from '../../components/RowSelector.vue'
import settingService from '@/services/settingService'
import { ApiBooleanResult } from '@/services/data'

@Component({
  components: {
    RowSelector
  }
})
export default class UserSettings extends Vue {
  editing: string = null

  userSettings: Record<string, any> = {
    StrikeProxPercent: ''
  }

  async mounted () {
    const usRes = await settingService.readUserSettingsByNamesAsync(['StrikeProxPercent', 'StockDataSource'])
    if (usRes.Error) {
      await this.$alert(usRes.Error)
    }
    if (usRes.Result) {
      usRes.Result.forEach(p => {
        this.userSettings[p.Name] = p.Value
      })
    }
  }

  async updateUserSetting (field: string) {
    const value = this.userSettings[field].toString()
    const res = await settingService.addOrUpdateUserSettingAsync([{
      Name: field,
      Value: value,
      ValueType: 'Number'
    }])
    this.showMessage(res)
  }

  private showMessage (res: ApiBooleanResult) {
    if (res.Error) {
      this.$message({ message: this.$t('msg.' + (res.Error || 'unableToSave')) as string, type: 'error' })
    } else {
      this.$message({ message: this.$t('msg.dataSaved') as string, type: 'success' })
    }
  }
}
